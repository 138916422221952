<template>
    <div class="add-box">
        <i-header :title="$route.query.label" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <component key="static" v-if="config['headline'] && config['headline'].length != 0" :is="$evalTemplate('Vnode', config['headline'].template)"></component>
            <div class="desc">{{ message }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    components: {},
    data() {
        return {
            title: "会议总结",
            loading: false,
            message: "",
            config: {}
        };
    },
    created() {
        this.init();
        //初始化

        this.message = this.$route.query.OldData;
    },
    methods: {
        async init() {
            let _conf = this.$store.state.summaryConfig || []
            let context = this
            _conf.forEach((item) => {
                try {
                    if (item.expr && eval(item.expr)) {
                        this.config[item.code] = item
                    }
                } catch (error) {
                    console.log(error,item.expr);
                }
            })
        },
        toBack() {
            this.$router.back();
        },
    },
};
</script>

<style lang="less" scoped>
.add-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    .cnt-box {
        padding: 0 0.25rem;
        box-sizing: border-box;
        .desc {
            margin-top: 0.1rem;
        }
    }
    .submit-btn {
        width: 88%;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 0.3rem;
        margin: 11.73vw auto 0 auto;
        font-size: 0.15rem;
        background: var(--themeColor);
        display: block;
        color: #fff;
        text-align: center;
    }
}
</style>
