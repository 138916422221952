var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-box" },
    [
      _c("i-header", {
        attrs: { title: _vm.$route.query.label },
        on: { "back-event": _vm.toBack },
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        [
          _vm.config["headline"] && _vm.config["headline"].length != 0
            ? _c(_vm.$evalTemplate("Vnode", _vm.config["headline"].template), {
                key: "static",
                tag: "component",
              })
            : _vm._e(),
          _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.message))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }